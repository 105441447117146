export default {
    landing: {
        title: 'Passwörter sicher übertragen',
        subtitle: '', 
        introText: 'Erstelle hier deinen privaten Zugang zum Passwort Sharing Tool. Der Schlüssel wird anschließend in deinem Browser gespeichert. Wenn du den Zugang auch in anderen Browsern benutzen möchtest, kannst du den Schlüssel herunterladen.',
        key: 'Schlüssel speichern',
        copyUrl: 'URL kopieren',
        goToAdmin: 'Admin-Bereich',
        createAccess: 'Zugang generieren',
        urlGenerated: {
            title: 'Deine URL',
            subtitle: '',
            text: 'Versende deine URL an jemanden den du kennst und lass dir Zugriff auf seine oder ihre Passwörter geben. Sicher, verschlüsselt und direkt.'
        },
        errors: {
            urlInvalid: 'Die URL ist nicht gültig.',
        }
    },
    admin: {
        noPasswords: 'Es wurden noch keine Passwörter geteilt 😥'
    },
    toast: {
        copySuccess: 'Erfolgreich kopiert',
        copyFailure: 'Beim Kopieren ist etwas schief gelaufen 🙁',
        urlNotFound: 'Leider gibt es die URL nicht.',
        urlNotCreated: 'Die URL konnte nicht erstellt werden.',
    },
    keyModal: {
        verify: 'Als Nutzer verifizieren',
        uploadKeyHere: 'Lade hier deinen privaten Schlüssel hoch',
        verifyHint: 'Füge deinen Schlüssel hier ein oder ziehe die Schlüsseldatei per Drag & Drop auf das Feld.',
        useKey: 'Schlüssel verwenden'
    },
    passwordAdd: {
        title: 'Teile deine Passwörter',
        subtitle: '',
        share: 'Teilen',
        sharingSuccessful: 'Das Passwort wurde übermittelt.',
        placeholders: {
            login: 'Login',
            password: 'Passwort',
            url: 'URL',
            encryptedText: 'verschlüsselter Freitext',
        },
        errors: {
            noPublicKey: 'Kein Public Key durch den Admin angegeben!',
            encryptionFailed: 'Deine Daten konnten nicht verschlüssel werden.',
            missingInfo: 'Du musst mindestens den Login und das Passwort angeben!',
        }
    },
    main: {
        verifyAsUser: 'Als Nutzer verifizieren',
        backToPassword: 'Zurück zur Passworteingabe',
        verifyHint: 'Copy and past your key here or drag & drop your key file.',
        useKey: 'use key',
        nav: {
            downloadKey: 'Schlüssel herunterladen',
            refresh: 'Passwörter neu laden',
            copyUrl: 'URL kopieren',
            removeKey: 'Schlüssel aus Browserspeicher entfernen',
        }
    },
    passwordList: {
        url: 'URL',
        login: 'Login',
        password: 'Passwort',
    }
}