<template>
    <main>
        <div class="main-container" :class="{hide: isLoading}">
            <nav :class="{hide: !isAdminView, background: isScroll}" :key="$root.$i18n.locale">
                <IconButton icon="download" @click="$download('pw_share_private_key.txt', $privateKey)" v-tooltip="$t('main.nav.downloadKey')" position="bottom"/>
                <IconButton icon="reload"   @click="reloadPasswords" v-tooltip="$t('main.nav.refresh')" position="bottom"/>
                <IconButton icon="copy"     @click="copyUrl" v-tooltip="$t('main.nav.copyUrl')" position="bottom"/>
                <IconButton icon="trash"    @click="clearStorage" v-tooltip="$t('main.nav.removeKey')" position="bottom"/>
            </nav>
            <nav :class="{hide: isAdminView}">
                <Button class="flex" :class="{hide: displayMode == 0}" @click="changeDisplayMode(0)" >
                    <Icon image="user" color="white"/>
                    <span>{{ $t('main.verifyAsUser') }}</span>
                </Button>
                <Button class="flex" :class="{hide: displayMode == 1}" @click="changeDisplayMode(1)" >
                    <Icon image="key" color="white"/>
                    <span>{{ $t('main.backToPassword') }}</span>
                </Button>
            </nav>
            <div class="swap-container show-if-loaded">
                <div class="handle" :style="{'margin-left': displace + '%'}"></div>
                <KeyModal v-if="!isAdminView" class="swappable" :class="{hide: displayMode != 0}" @submit="usePrivateKey"/>
                <PasswordAdd  class="swappable" :class="{hide: displayMode != 1}"/>
                <PasswordList ref="passwordList" v-show="isAdminView" class="swappable" :class="{hide: displayMode != 2}"/>
            </div>
        </div>
    </main>
</template>

<script>
// @ is an alias to /src
import { IconButton, Icon, Button } from '@/components/UI'
import { PasswordAdd, PasswordList, KeyModal } from '@/views/subviews/Main'
import { ECDH, Toast } from '@/classes'

export default {
    name: 'Home',
    components: {
        PasswordAdd,
        PasswordList,
        KeyModal,
        IconButton,
        Icon,
        Button,
        // Loader
    },
    data: function() {
        return {
            isLoading: true,
            isScroll: false,
            isAdminView: false,
            displayMode: 1,
            displace: -100,
            secret: null,
        }
    },
    methods: {
        usePrivateKey: async function() {
            if(await this.keysMatch())
            {
                this.$storage('key', this.$privateKey);
                this.isAdminView = true;
                this.displace = -100;
                this.updateDisplayMode();
                this.$refs.passwordList.init();
            }
        },
        changeDisplayMode: function(mode) {
            this.displace = mode * -100;
            this.updateDisplayMode()
        },
        copyUrl: function() {
            this.$clipboard(window.location, {
                success: this.$t('toast.copySuccess'),
                failure: this.$t('toast.copyFailure'),
            });
        },
        reloadPasswords: function() {
            this.$refs.passwordList.init();
        },
        clearStorage: function() {
            window.localStorage.clear();
            this.$privateKey = null;
            this.isAdminView = false;
            this.displace = -100;
            this.updateDisplayMode();
        },
        updateDisplayMode: function() {
            switch(true)
            {
                case !this.isAdminView && this.displace == 0:
                    this.displayMode = 0;
                break;
                case this.isAdminView && this.displace != 0:
                    this.displayMode = 2;
                break;
                case this.displace != 0:
                    this.displayMode = 1;
                break;
            }
        },
        keysMatch: async function(preventToast) {
            if(this.$privateKey)
            {
                try
                {
                    var ecdh = new ECDH({
                        publicKey: this.$publicKey,
                        privateKey: this.$privateKey,
                    });

                    await ecdh.init();
                }
                catch(e)
                {
                    if(!preventToast)
                    {
                        Toast.make('Der angegebene Schlüssel ist ungültig.').error().show();
                    }
                    return false;
                }

                try
                {
                    await ecdh.usePubKey(this.$publicKey);
                }
                catch(e)
                {
                    Toast.make('Der Schlüssel der URL scheint beschädigt zu sein.').error().show();
                    return false;
                }

                try 
                {
                    await ecdh.decrypt(this.secret);
                    return true;
                }
                catch(e)
                {
                    if(!preventToast)
                    {
                        Toast.make('Die Schlüssel passen nicht zusammen.').error().show();
                    }
                }
                return false;
            }
        },
    },
    mounted: function() {
        this.$shareController.find(this.$route.params.hash)
        .then(async res => {
            this.secret      = res.secret;
            this.$publicKey  = res.key;
            this.$privateKey = this.$storage('key');

            if(await this.keysMatch(true))
            {
                this.isAdminView = true;
                this.updateDisplayMode();
                await this.$refs.passwordList.init();
            }
            else 
            {
                this.$privateKey = null;
            }

            this.isLoading = false;
        }, ({res}) => {
            if(res && res.code == 404)
            {
                Toast.make(this.$t('toast.urlNotFound')).error().show();
            }
            this.$router.push({ name: 'Landing' });
        });
    },
}
</script>

<style lang="scss" scoped>
nav {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    @include flexbox;
    font-size: 0.8em;

    &.hide {
        display: none;
    }

    button {
        margin: 0 5px;
    }
}
main {
    width: 100%;
    height: 100%;
}
.main-container {
    position: relative;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    transition: opacity .3s ease-in-out;
    &.hide {
        opacity: 0;
    }
}
.space {
    margin: 20px auto 0;
}
.loader {
    position: absolute;
    top: 40%;
    left: 50%;
}
button {
    &.flex {
        padding-left: 8px;
        padding-right: 12px;
        .icon {
            margin-right: 6px;
        }
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        &.hide {
            display: none;
        }
    }
}
</style>