<template>
    <button class="icon-button" type="button" @click="onClick">
        <Icon :image="icon" :color="iconColor"/>
    </button>
</template>

<script>
import Icon from "./icon";

export default {
    components: {
        Icon,
    },
    props: {
        icon: String,
        iconColor: {
            default: '',
        }
    },
    methods: {
        onClick: function() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>

button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    position: relative;
    overflow: hidden;
    padding: 8px;
    font-size: 1em;
    display: block;
    border: none;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        opacity: .7;
    }

    // ripple

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 100%;
        padding-bottom: 100%;
        height: 0;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.6);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%, -50%);
        transform-origin: 0 0;
    }

    @keyframes ripple {
        0% {
            transform: scale(0, 0) translate(-50%, -50%);
            opacity: 1;
        }
        15% {
            transform: scale(0.7, 0.7) translate(-50%, -50%);
            opacity: 1;
        }
        100% {
            transform: scale(1.4, 1.4) translate(-50%, -50%);
            opacity: 0;
        }
    }

    &:focus:not(:active) {
        &::after {
            animation: ripple 1s ease-out;
        }
    }
}
</style>