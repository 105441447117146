import Vuex from 'vuex';
export default {
    install: function (Vue, options) {
        options = options || {};
        // const store = options.store || {state:{}}
        Vue.use(Vuex);
        var store = new Vuex.Store({ state: {} });
        var globals = options.globals || {};
        Object.keys(globals).forEach(function (key) {
            Vue.set(store.state, key, globals[key]);
        });
        var computed = {};
        Object.keys(globals).forEach(function (key) {
            computed["" + key] = {
                get: function () { return store.state[key]; },
                set: function (v) { Vue.set(store.state, key, v); }
            };
        });
        Vue.mixin({
            computed: computed
        });
    }
};