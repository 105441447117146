<template>
    <div class="textarea">
        <icon :image="icon"></icon>
        <textarea v-model="inputVal" :placeholder="placeholder" rows="4">
        </textarea>
    </div>
</template>

<script>
import icon from '@/components/UI/icon.vue';

export default {
    props: {
        value: String,
        icon: String, 
        type: String,
        placeholder: String,
    },
    components: {
        icon
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.textarea {
    position: relative;
    padding: 8px 8px 8px 38px;
    border: 1px solid $color2;
    border-radius: 4px;
    background: white;

    .icon {
        position: absolute;
        top: 0;
        left: 2px;
    }

    textarea {
        font-family: $font-family;
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        min-width: 100%;
        resize: none;
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        display: block;
        width: 100%;
        height: 100%;
        font-size: .9em;
        @include placeholder($color2, 0.5);
    } 
}
</style>