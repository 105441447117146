<template>
    <div>
        <div class="centered-dialog center">
            <h1>{{ $t('keyModal.verify') }}</h1>
            <h2>{{ $t('keyModal.uploadKeyHere') }}</h2>
            <div ref="drop-area" class="key-container" :class="{dragging: addDragOverStyle}" @drop.prevent="addFile" @dragover.prevent>
                <Textarea class="space private-key-input" icon="message" v-model="$privateKey" :placeholder="$t('keyModal.verifyHint')"/>
                <Button class="space" :text="$t('keyModal.useKey')" @click="submit"></Button>
                <Icon image="add-file"/>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { Button, Textarea, Icon } from '@/components/UI'
import { Toast } from '@/classes'

export default {
    name: 'Home',
    components: {
        Button,
        Textarea,
        Icon
    },
    data: function() {
        return {
            login: null,
            password: null,
            url: null,
            comment: null,
            prv: null,
            addDragOverStyle: false,
        }
    },
    mounted() {
        var dragOverEvents   = ['dragover', 'dragenter'],
            dragLeaveEvents  = ['dragleave', 'dragend'];

        dragOverEvents.forEach(e => {
            this.$refs['drop-area'].addEventListener(e, (ev) => {
                this.addDragOverStyle = true;
                ev.preventDefault();

            });
        })

        dragLeaveEvents.forEach(e => {
            this.$refs['drop-area'].addEventListener(e, () => {
                this.addDragOverStyle = false;
            });
        })
    },
    methods: {
        addFile(e) {
            this.addDragOverStyle = false;

            var droppedFiles = e.dataTransfer.files;

            if(droppedFiles && droppedFiles.length == 1)
            {
                var readAsArrayBuffer = true;
                var file = droppedFiles[0];
                var reader = new FileReader();
                reader.onerror = function () {
                    Toast.make("Die Datei konnte nicht gelesen werden.").error().show();
                }
                reader.onload = (evt) => {
                    if(readAsArrayBuffer)
                    {
                        var arr = (new Uint8Array(evt.target.result)).subarray(0, 4);
                        var header = "";

                        for(var i = 0; i < arr.length; i++) {
                            header += arr[i].toString(16);
                        }
                        // 65794a6a is the value for text mime type files
                        switch (header) {
                            case '61736461':
                            case '65794a6a':
                                readAsArrayBuffer = false;
                                reader.readAsText(file, "UTF-8");
                                break;
                        
                            default:
                                Toast.make('Der Dateityp wird nicht unterstüzt.').error().show();
                                break;
                        }
                    }
                    else 
                    {
                        this.$privateKey = evt.target.result;
                        Toast.make('Schlüssel wurde importiert.').show();
                    }
                };
                reader.readAsArrayBuffer(file);
            }
            else 
            {
                Toast.make('Bitte wähle nur eine Datei aus.').error().show();
            }
        },
        submit: async function() {
            this.$emit('submit');
        },
    },
}
</script>

<style lang="scss" scoped>
.space {
    margin: 20px auto 0;
}
.private-key-input {
    height: 150px;
}
h2 {
    margin-bottom: 1em;
}

$icon-size: 90px;

.key-container {
    position: relative;
    
    .icon {
        position: absolute;
        width: $icon-size;
        height: $icon-size;
        top: 50%;
        left: 50%;
        margin: -70px 0 0 (-$icon-size/2);
        opacity: 0;
        transform: scale(0);
        transition: opacity .2s ease-in-out, transform .3s ease-in-out;
        transform-origin: center center;
    }

    .textarea {
        opacity: 1;
        transition: .2s ease-in-out;
    }

    &.dragging {
        .icon {
            pointer-events: none;
            opacity: 1;
            transform: scale(1.2);
            transition: opacity .2s ease-in-out, transform .1s ease-in-out;
        }
        .textarea {
            opacity: .4;
        }
    }
}
</style>