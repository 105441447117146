<template>
    <div class="tooltip" :style="{
        opacity: opacity,
        transform: 'translate(' + left + 'px, ' + top + 'px)'
    }">{{text}}</div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    props: {
        text: String,
        position: String,
        orientation: String,
        reference: HTMLElement,
        parent: Object,
        html: HTMLElement,
    },
    data: function() {
        return {
            top: 0,
            left: 0,
            translate: 0,
            opacity: 0,
            timeout: null,
            isVisible: false,
        }
    },
    methods: {
        show: function() {
            if(this.isVisible) return;

            clearTimeout(this.timeout);

            if(!this._isMounted) 
            {
                this.$mount();
            }

            this.html.appendChild(this.$el);

            var rect = this.reference.getBoundingClientRect();
            var elRect = this.$el.getBoundingClientRect();

            switch (this.position) 
            {
                case 'bottom':
                    this.top = rect.bottom + 4;
                    break;
            
                default:
                    this.top = rect.top - 4 - elRect.height;
                    break;
            }

            if(this.top + elRect.height > document.body.offsetHeight)
            {
                // tooltip would overlap on left browser side
                this.top = rect.top - 4 - elRect.height;
            }

            switch (this.orientation) 
            {
                case 'left':
                    this.left = rect.left;
                    break;

                case 'right':
                    this.left = rect.left + rect.width - elRect.width;
                    break;
            
                default: // centered
                    this.left = rect.left + rect.width / 2 - elRect.width / 2;
                    break;
            }

            var elRight = this.left + elRect.width;

            if(this.left < 0)
            {
                // tooltip would overlap on left browser side
                this.left = 0 - elRect.left + 10;
            }
            else if(elRight > document.body.offsetWidth)
            {
                // tooltip would overlap on right browser side
                 this.left = this.left - (elRight - document.body.offsetWidth) - 10;
            }

            setTimeout(() => {    
                this.opacity = 1;
            }, 10)

            this.isVisible = true;
        },
        hide: async function() {
            this.opacity = 0;
            this.isVisible = false;
            return new Promise((resolve) => {
                this.timeout = setTimeout(function() {
                    resolve();
                }, 240)
            });
        }
    }
})
</script>

<style lang="scss" scoped>
div {
    top: 0;
    left: 0;
    text-align: center;
    font-size: .8em;
    border-radius: 4px;
    display: inline-block;
    background: $color2;
    color: white;
    padding: 4px 8px;
    position: absolute;
    transition: opacity .2s ease-in-out;
    box-shadow: 0 0 5px -2px black;
    z-index: 1;
    max-width: 80%;
    pointer-events: none;

    @media screen and (min-width: 1100px) {
        max-width: 800px;
    }
}
</style>