import { Request } from "@/classes";

var ShareController = function() {
    var uri = 'share';

    ShareController.prototype.create = async function(key, secret) {
        return new Promise(function(resolve, reject) {
            Request({
                url: uri,
                method: 'POST',
                data: {
                    key: key,
                    secret: secret,
                }
            }, function(err, res) {
                if(err)
                {
                    reject(err);
                }
                else
                {
                    resolve(res);
                }
            });
        });
    }

    ShareController.prototype.find = async function(hash) {
        return new Promise(function(resolve, reject) {
            Request({
                url: uri + '/' + hash,
                method: 'GET',
            }, function(err, res) {
                if(err)
                {
                    reject({res, err});
                }
                else
                {
                    resolve(res);
                }
            });
        });
    }
}

export default ShareController;