<template>
  <div id="app">
    <router-view/>
    <Button class="lang-switch" @click="showLanguageMenu = !showLanguageMenu">
        <Icon image="world" color="white"/>
    </Button>
    <div class="lang-menu" :style="!showLanguageMenu ? 'display: none;' : ''">
        <div v-for="l in languages" :key="l" @click="changeLocale(l)">{{ l }}</div>
    </div>
  </div>
</template>

<script>
import { Button, Icon } from "@/components/UI";

export default {
    components: {
        Button,
        Icon,
    },
    data() {
        return {
            showLanguageMenu: false,
        }
    },
    computed: {
        languages: {
            get() {
                return ['en','de'];
            }
        }
    },
    methods: {
        changeLocale(lang) {
            this.showLanguageMenu = false;
            this.$root.$i18n.locale = lang;
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/scss/layout.scss';
#app {
  width: 100%;
  height: 100%;
  position: relative;
}
#nav {
  position: absolute;
}
button.lang-switch {
    position: absolute;
    top: 10px;
    left: 10px;
    display: inline-block;
    width: auto;
    overflow: visible;
}
.lang-menu {
    background: #fff;
    color: $color2;
    position: absolute;
    top: 20px;left: 20px;
    border-radius: 5px;
    overflow: hidden;
    &>div {
        padding: 8px 16px;
        cursor: pointer;
        &:hover {
            background: #eee;
        }
    }
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
</style>
