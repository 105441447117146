import Vue from 'vue'
import Vuex from 'vuex'
import VueGlobalVar from './vue-global-var'

import { Toast } from "@/classes";
import { ShareController, PasswordController } from '@/controller';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
});

Vue.use(VueGlobalVar, {
    globals: {
        $publicKey: null,
        $privateKey: null,
        $shareController: new ShareController(),
        $passwordController: new PasswordController(),
        $storage: function(key, value) {
            if(undefined === value) 
            {
                return window.localStorage.getItem(key);
            } 
            else 
            {
                window.localStorage.setItem(key, value);
            }
        },
        $clipboard: function(str, messages) {
            window.getSelection().removeAllRanges();
        
            var el = document.createElement('textarea');
        
            el.value = str;
        
            el.setAttribute('readonly', '');
            el.style = {position: 'absolute', left: '-9999px'};
            document.body.appendChild(el);
        
            el.select();
        
            var copied = document.execCommand('copy');
        
            document.body.removeChild(el);
        
            if(copied)
            {
                Toast.make(messages.success).show();
            }
            else
            {
                Toast.make(messages.failure).error().show();
            }
        },
        $download: function(filename, text) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    },
});
