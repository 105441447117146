export default {
    landing: {
        title: 'Transfer passwords securely',
        subtitle: '',
        introText: 'Create your private access to the password sharing tool here. The key will be saved in your browser. If you want to use the access also in other browsers, you can download the key.',
        key: 'download key',
        copyUrl: 'copy URL',
        goToAdmin: 'go to passwords',
        createAccess: 'start sharing',
        urlGenerated: {
            title: 'Your URL',
            subtitle: '',
            text: 'Send your URL to someone you know and get access to his or her passwords. Securely, encrypted and immediately.'
        },
        errors: {
            urlInvalid: 'Die URL ist nicht gültig.',
        }
    },
    admin: {
        noPasswords: 'No passwords are shared yet 😥'
    },
    toast: {
        copySuccess: 'Successfully copied',
        copyFailure: 'There was an error while copying 🙁',
        urlNotFound: 'Unfortunately, the given URL does not exist.',
        urlNotCreated: 'Unfortunately, the URL was not created.',
    },
    keyModal: {
        verify: 'Verify as user',
        uploadKeyHere: 'Upload your key here',
        verifyHint: 'Copy and past your key here or drag & drop your key file.',
        useKey: 'use key'
    },
    passwordAdd: {
        title: 'Share your passwords',
        subtitle: '',
        share: 'share',
        sharingSuccessful: 'The password data was shared successfully.',
        placeholders: {
            login: 'Login',
            password: 'Password',
            url: 'URL',
            encryptedText: 'securely encrypted text',
        },
        errors: {
            noPublicKey: 'There is no public key given by the administrative user.',
            encryptionFailed: 'Unfortunately, the provided data was not encrypted correctly. Please try again.',
            missingInfo: 'Please provide at least a login and the corresponding password!',
        }
    },
    main: {
        verifyAsUser: 'Verify as user',
        backToPassword: 'Enter key',
        verifyHint: 'Copy and past your key here or drag & drop your key file.',
        useKey: 'use key',
        nav: {
            downloadKey: 'download key',
            refresh: 'refresh password list',
            copyUrl: 'copy URL',
            removeKey: 'remove key from browser memory',
        }
    },
    passwordList: {
        url: 'URL',
        login: 'Login',
        password: 'Password',
    }
}