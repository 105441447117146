<template>
    <div class="list">
        <ListElement 
            v-if="!noHeader" 
            :item="{
                loginField: this.$t('passwordList.login'),
                passwordField: this.$t('passwordList.password'),
                urlField: this.$t('passwordList.url'),}" 
            :isHeadline="true" 
            :key="$root.$i18n.locale"
        />
        <ListElement v-for="(item, index) in items" :item="item" :key="item.id" class="inverted" :showActions="true" @remove="remove(index)"/>
    </div>
</template>

<script>
import ListElement from "./listelement";

export default {
    name: "HelloWorld",
    props: {
        items: Array,
        noHeader: Boolean,
    },
    components: {
        ListElement,
    },
    methods: {
        remove: function(index) {
            this.items.splice(index, 1);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    overflow-x: hidden;
    max-height: 100%;
}
</style>
