/* eslint-disable */
export var Toast = (function () {
    var ANIM_SPEED = 400;

	var Transitions = {
		SHOW: {
			'transition': 'opacity ' + ANIM_SPEED + 'ms, transform ' + ANIM_SPEED + 'ms',
			'opacity': '1',
			'transform': 'translateY(-100%) translateZ(0)'
		},

		HIDE: {
			'opacity': '0',
			'transform': 'translateY(150%) translateZ(0)'
		}
	};

	var Styles = {
		'background': 'rgba(0, 0, 0, .85)',
		'box-shadow': '0 0 10px -5px rgba(0, 0, 0, .8)',
		'cursor': 'pointer',

		'border-radius': '3px',

		'z-index': '99999',

		'color': 'rgba(255, 255, 255, .9)',
		
		'padding': '10px 15px',
		'max-width': '250px',
		'width': '100%',
		'word-break': 'keep-all',
		'margin': '0 auto',
		'text-align': 'center',

		'position': 'fixed',
		'bottom': '0',
		'left': '0',
		'right': '0',

		'transform': 'translateY(150%) translateZ(0)',
		'opacity': '0'
	};

	var Settings = {
		duration: 4000
	};

	function stylize(element, styles) {
		Object.keys(styles).forEach(function(style) {
			element.style[style] = styles[style];
		});
	};

	function merge(settings, customized) {
		var merged = customized;
		for(var prop in settings) {
			if(merged.hasOwnProperty(prop)) {
				if(settings[prop] !== null && settings[prop].constructor === Object) {
					merged[prop] = merge(settings[prop], merged[prop]);
				}
			} else {
				merged[prop] = settings[prop];
			}
		}
		return merged;
	}

	var _toast   = null;
	var _timeout = null;

	/**
	 * The main Toast object
	 */
	function Toast() {
		this.element  		= null;
		this.settings 		= {
			duration: Settings.duration,
		};
		this.onclick = function () {};
	}

	Toast.prototype.styles  = Styles;
	Toast.prototype.transitions = Transitions;
	Toast.prototype.successStyles = null;
	Toast.prototype.errorStyles   = null;

	Toast.defineStyles = function(css, options) {
		Toast.prototype.styles = merge(Styles, css);
		if(options) 
		{
			if(options.success) 
			{
				Toast.prototype.successStyles = options.success;
			}
			if(options.error) 
			{
				Toast.prototype.errorStyles   = options.error;
			}
			if(options.transitions) 
			{
				Toast.defineTransitions(options.transitions);
			}
		}
	}

	Toast.defineTransitions = function(css) {
		css.show = css.show || {};
		css.hide = css.hide || {};
		Toast.prototype.transitions = {
			SHOW: merge(Transitions.SHOW, css.show),
			HIDE: merge(Transitions.HIDE, css.hide),
		};
	}

	Toast.make = function(text) {
		if(_toast) 
		{
			var x = _toast;
			x.hide()
			x = null;
		}
		_toast = new Toast();
		_toast.element = document.createElement('div');
		_toast.element.addEventListener('click', function(e) {
			_toast.onclick(e);
			_toast.hide();
		});
		_toast.element.addEventListener('touch', function(e) {
			_toast.onclick(e);
			_toast.hide();
		});

		_toast.element.innerText = text;

		stylize(_toast.element, Toast.prototype.styles);

		return _toast;
	}

	/**
	 * set css for the toast
	 * @param  {Object}  styles  An object containing the style to apply
	 * @return                   this
	 */
	Toast.prototype.css = function(styles) {
		stylize(this.element, styles);
		return this;
	};

	/**
	 * set type specific styles for the toast (error, success)
	 * @return                   this
	 */
	Toast.prototype.error = function() {
		if(Toast.prototype.errorStyles)
		{
			stylize(this.element, Toast.prototype.errorStyles);
		}
		return this;
	};

	Toast.prototype.success = function() {
		if(Toast.prototype.successStyles)
		{
			stylize(this.element, Toast.prototype.successStyles);
		}
		return this;
	};

	/**
	 * set showing duration for the toast
	 * @param  {number} duartion time [ms] the toast shows up in the browser
	 * @return                   Returns this
	 */
	Toast.prototype.duration = function(duration) {
		this.settings.duration = duration;
		return this;
	};

	/**
	 * Show the Toast
	 * @param  {Object} options The object containing the options for the Toast
	 */
	Toast.prototype.show = function(options) {
		var _this = this;

		document.body.insertBefore(this.element, document.body.firstChild);

		// This is a hack to get animations started. Apparently without explicitly redrawing, it'll just attach the class and no animations would be done
		this.element.offsetHeight;

		stylize(this.element, Toast.prototype.transitions.SHOW);

		// Hide the Toast after the specified time
		clearTimeout(_timeout);
		_timeout = setTimeout(function() { 
			_this.hide();
		}, this.settings.duration);
	};
	
	/**
	 * Hide the Toast that's currently shown
	 */
	Toast.prototype.hide = function() {
		var _this = this;
		
		stylize(this.element, Toast.prototype.transitions.HIDE);

		// Destroy the Toast element after animations end
		clearTimeout(_timeout);
		setTimeout(function() {
			_this.destroy();
		}, ANIM_SPEED);
	};

	/**
	 * Clean up after the Toast slides away. Namely, removing the Toast from the DOM. After the Toast is cleaned up, display the next Toast in the queue if any exists
	 */
	Toast.prototype.destroy = function() {
		document.body.removeChild(this.element);
		if(this == _toast) 
		{
			_toast = null;
		}
	};

	return Toast;
})();