/* eslint no-console: 0 */  // --> OFF
export function ECDH(keyPair) {
    var self = this;

    var _curve  = 'P-384';
    var _aesAlg = 'AES-GCM';
    var _myKeys = null; // { privatyKey: Cryptokey, publicKey: Cryptokey }
    var _pubKey = null;
    var _eccUsages = ["deriveKey", "deriveBits"];

    this.onInitialized = function() {};
    
    this.uIntToString = function(uintArray) {
        var encodedString = String.fromCharCode.apply(null, uintArray),
            decodedString = decodeURIComponent(escape(encodedString));
        return decodedString;
    }
    
    this.arrayBufferToBase64 = function(buffer) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }
    
    this.base64ToArrayBuffer = function(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    
    this.stringToUint8 = function(msg) {
        var enc = new TextEncoder();
        return enc.encode(msg);
    }

    this.usePubKey = async function(pubKey) {
        _pubKey = await importPublicKey(pubKey);
    }

    this.exportPrivateKey = async function() {
        var exportKey = await window.crypto.subtle.exportKey("jwk", _myKeys.privateKey);
        return btoa(JSON.stringify(exportKey));
    }

    this.exportPublicKey = async function() {
        return window.crypto.subtle.exportKey("spki", _myKeys.publicKey).then(
            function(keydata) {
                return self.arrayBufferToBase64(keydata);
            }
        );
    }
    
    this.encrypt = async function (data) {
        var key = await generateAesKey(_myKeys, _pubKey);
        var iv = window.crypto.getRandomValues(new Uint8Array(16));
        return crypto.subtle.encrypt({
            name: _aesAlg,
            iv: iv,
            length: 256
        }, key, self.stringToUint8(data))
        .then(function(encrypted) {
            return appendIv(self.arrayBufferToBase64(encrypted), self.arrayBufferToBase64(iv));
        });
    }
    
    this.decrypt = async function (data) {
        var key = await generateAesKey(_myKeys, _pubKey);
        var preparedData = splitIv(data);
        return crypto.subtle.decrypt({
            name: _aesAlg,
            iv: self.base64ToArrayBuffer(preparedData.iv),
        }, key, self.base64ToArrayBuffer(preparedData.msg))
        .then(function(decrypted) {
            return self.uIntToString(new Uint8Array(decrypted));
        }, err => {
            throw Error(err);
        });
    }

    this.init = async function () {
        if(!keyPair) 
        {
            _myKeys = await makeKeyPair();
            self.onInitialized();
        }
        else
        {
            _myKeys = {
                publicKey: await importPublicKey(keyPair.publicKey),
                privateKey: await importPrivateKey(keyPair.privateKey),
            };
            self.onInitialized();
        }
    }
    
    function appendIv(base64, iv) {
        return base64 + '.' + iv;
    }
    
    function splitIv(base64Iv) {
        var split = base64Iv.split('.');
        return {
            msg: split[0],
            iv: split[1]
        }
    }

    async function importPrivateKey(pem) {
        return window.crypto.subtle.importKey(
            "jwk",
            JSON.parse(atob(pem)),
            {
                name: "ECDH",
                namedCurve: _curve,
            },
            true,
            _eccUsages
        );
    }

    async function importPublicKey(pem) {
        return window.crypto.subtle.importKey(
            "spki",
            self.base64ToArrayBuffer(pem),
            {
                name: "ECDH",
                namedCurve: _curve,
            },
            true,
            []
        ).then(x => {
            return x;
        });
    }

    async function makeKeyPair() {
        return window.crypto.subtle.generateKey(
            {
                name: "ECDH",
                namedCurve: _curve, //can be "P-256", "P-384", or "P-521"
            },
            true, //whether the key is extractable (i.e. can be used in exportKey)
            ["deriveKey", "deriveBits"] //can be any combination of "deriveKey" and "deriveBits"
        ).then(function(key) { 
            return key; 
        });
    }

    async function generateAesKey() {
        return window.crypto.subtle.deriveKey(
            {
                name: "ECDH",
                namedCurve: _curve, //can be "P-256", "P-384", or "P-521"
                public: _pubKey, //an ECDH public key from generateKey or importKey
            },
            _myKeys.privateKey, //your ECDH private key from generateKey or importKey
            { 
                //the key type you want to create based on the derived bits
                name: _aesAlg, //can be any AES algorithm ("AES-CTR", "AES-CBC", "AES-CMAC", "AES-GCM", "AES-CFB", "AES-KW", "ECDH", "DH", or "HMAC")
                //the generateKey parameters for that type of algorithm
                length: 256, //can be  128, 192, or 256
            },
            true, //whether the derived key is extractable (i.e. can be used in exportKey)
            ["encrypt", "decrypt"] //limited to the options in that algorithm's importKey
        )
        .then(function(keydata) {
            //returns the exported key data
            return keydata;
        })
        .catch(function(err) {
            throw Error(err);
        });
    }
}