<template>
    <button type="button" @click="onClick"><slot/>{{text}}</button>
</template>

<script>
export default {
    props: {
        text: String,
    },
    methods: {
        onClick: function() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>

button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $color1;
    color: white;
    position: relative;
    overflow: hidden;
    padding: 8px;
    font-size: 1em;
    display: block;
    border: none;
    border-radius: 4px;
    width: 100%;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;

    &:hover {
        opacity: .7;
    }

    // ripple

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 100%;
        padding-bottom: 100%;
        height: 0;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.2);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%, -50%);
        transform-origin: 0 0;
    }

    @keyframes ripple {
        0% {
            transform: scale(0, 0) translate(-50%, -50%);
            opacity: 1;
        }
        15% {
            transform: scale(0.7, 0.7) translate(-50%, -50%);
            opacity: 1;
        }
        100% {
            transform: scale(1.4, 1.4) translate(-50%, -50%);
            opacity: 0;
        }
    }

    &:focus:not(:active) {
        &::after {
            animation: ripple 1s ease-out;
        }
    }
}
</style>