import Vue from 'vue'
import VueRouter from 'vue-router'
import { Main, Landing } from '@/views/'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: Landing,
    }, {
        path: '/:hash',
        name: 'Main',
        component: Main,
    }
]

const router = new VueRouter({
    routes
})

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (localStorage.getItem('jwt') == null) {
//             next({
//                 path: '/login',
//                 params: { nextUrl: to.fullPath }
//             })
//         } else {
//             let user = JSON.parse(localStorage.getItem('user'))
//             if (to.matched.some(record => record.meta.is_admin)) {
//                 if (user.is_admin == 1) {
//                     next()
//                 }
//                 else {
//                     next({ name: 'userboard' })
//                 }
//             } else {
//                 next()
//             }
//         }
//     } else if (to.matched.some(record => record.meta.guest)) {
//         if (localStorage.getItem('jwt') == null) {
//             next()
//         }
//         else {
//             next({ name: 'userboard' })
//         }
//     } else {
//         next()
//     }
// })

export default router
