<template>
    <div v-if="!item.error" class="list-element" :class="{headline: isHeadline}">
        <div class="url" @click="copy(item.urlField)">{{item.urlField}}</div>
        <div class="login" @click="copy(item.loginField)">{{item.loginField}}</div>
        <div class="password" @click="copy(item.passwordValue)">{{item.passwordField}}</div>
        <div v-if="showActions" class="actions">
            <IconButton
                v-if="item.comment && item.comment.length > 0"
                icon="message"
                v-tooltip="item.comment" position="bottom" orientation="right"
                @click="copy(item.comment)"
            />
            <IconButton icon="remove" @click="remove(item.id)"/>
        </div>
        <div v-else class="actions"></div>
    </div>
    <div v-else class="list-element">
        <div class="error">
            Dieser Datensatz konnte leider nicht entschlüsselt werden.
        </div>
        <div v-if="showActions" class="actions">
            <IconButton icon="remove" @click="remove(item.id)"/>
        </div>
    </div>
</template>

<script>
import { IconButton } from "@/components/UI";
import { Toast } from '@/classes';

export default {
    name: "HelloWorld",
    components: {
        IconButton
    },
    props: {
        item: Object,
        showActions: Boolean,
        isHeadline: Boolean
    },
    methods: {
        copy: function(str) {
            if(!this.isHeadline && str.length > 0) 
            {
                this.$clipboard(str, {
                    success: this.$t('toast.copySuccess'),
                    failure: this.$t('toast.copyFailure'),
                })
            }
        },
        remove: function(id) {
            var hash = this.$route.params.hash;

            if(hash) 
            {
                this.$passwordController.remove(hash, id)
                .then(() => {
                    Toast.make('Password wurde entfernt.').show();
                    this.$emit('remove');
                });
            }
            
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list-element {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    padding: 5px;

    .error {
        @include flexbox;
        @include flex(999,0,0);
        @include justify-content(center);
        font-weight: bold;
        color: #ff6060;
        padding: 5px;
        user-select: none;
    }

    &:nth-child(2n) {
        background: #fff;
        // background: rgba(255,255,255,0.7);
    }

    &:nth-child(2n + 1) {
        background: #f0f0f0;
        // background: rgba(240,240,240,0.7);
    }

    &.inverted {
        &:nth-child(2n) {
        background: #f0f0f0;
        // background: rgba(255,255,255,0.7);
    }

    &:nth-child(2n + 1) {
        background: #fff;
        // background: rgba(240,240,240,0.7);
    }

    }

    &.headline {
        background: transparent;
        font-weight: bold;

        .login,
        .password,
        .url {
            cursor: default;
        }
    }

    .login,
    .password,
    .url {
        @include flex(0 30%);
        width: 30%;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        user-select: none;
        cursor: pointer;
        &:hover {
            font-weight: bold;
        }
    }

    .login {
        padding: 5px 5px 7px;
    }

    .password {
        padding: 5px;
    }

    .url {
        padding: 5px;
    }

    .actions {
        @include flex(1 0 10%);
        width: 10%;
        min-width: 90px;
        box-sizing: border-box;
        @include flexbox;
        @include justify-content(flex-end);
        @include align-items(center);
        padding: 0 5px 0 0;

        .icon {
            margin: 0 3px;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }
        }
    }
}
</style>
