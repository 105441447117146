<template>
    <div v-bind:class="['icon', image, color]"></div>
</template>

<script>
export default {
    props: {
        image: String,
        color: {
            default: '',
        }
    }
}
</script>

<style lang="scss" scoped>

.icon {
    position: relative;
    width:  32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.lock {
        background-image: url(~@/assets/images/icons/lock.svg);
    }   
    
    &.key {
        background-image: url(~@/assets/images/icons/key.svg);
    }
    
    &.remove {
        background-image: url(~@/assets/images/icons/remove.svg);
    }

    &.user {
        background-image: url(~@/assets/images/icons/user.svg);
    }   
    
    &.world {
        background-image: url(~@/assets/images/icons/world.svg);
    }
    
    &.message {
        background-image: url(~@/assets/images/icons/message.svg);
    }
    
    &.copy {
        background-image: url(~@/assets/images/icons/copy.svg);
    }

    &.logout {
        background-image: url(~@/assets/images/icons/logout.svg);
    }
    
    &.reload {
        background-image: url(~@/assets/images/icons/reload.svg);
    }

    &.add-file {
        background-image: url(~@/assets/images/icons/add_file.svg);
    }

    &.download {
        background-image: url(~@/assets/images/icons/download.svg);
    }
    
    &.trash {
        background-image: url(~@/assets/images/icons/trash.svg);
    }

    &.white {
        &.lock {
            background-image: url(~@/assets/images/icons/white-lock.svg);
        }   
        
        &.key {
            background-image: url(~@/assets/images/icons/white-key.svg);
        }
        
        &.remove {
            background-image: url(~@/assets/images/icons/white-remove.svg);
        }

        &.user {
            background-image: url(~@/assets/images/icons/white-user.svg);
        }   
        
        &.world {
            background-image: url(~@/assets/images/icons/white-world.svg);
        }
        
        &.message {
            background-image: url(~@/assets/images/icons/white-message.svg);
        }
        
        &.copy {
            background-image: url(~@/assets/images/icons/white-copy.svg);
        }

        &.logout {
            background-image: url(~@/assets/images/icons/white-logout.svg);
        }
        
        &.reload {
            background-image: url(~@/assets/images/icons/white-reload.svg);
        }

        &.add-file {
            background-image: url(~@/assets/images/icons/white-add_file.svg);
        }

        &.download {
            background-image: url(~@/assets/images/icons/white-download.svg);
        }
        
        &.trash {
            background-image: url(~@/assets/images/icons/white-trash.svg);
        }
    }
}
</style>