import Tooltip from '@/components/directives/tooltip'

function show(el) {
    el.TOOLTIP.show();
}

function hide(el) {
    el.TOOLTIP.hide().then(function() {
        if(el.TOOLTIP && el.TOOLTIP.$root.$el.parentNode) 
        {
            el.TOOLTIP.$root.$el.parentNode.removeChild(el.TOOLTIP.$root.$el)
        }
    });
}

// Add/update Tooltip on our element
const applyTooltip = (el, bindings, vnode) => {
    if (!el.TOOLTIP) {
        const $parent = vnode.context
        
        el.TOOLTIP = new Tooltip({
            propsData: {
                text: bindings.value,
                orientation: vnode.data.attrs.orientation,
                position: vnode.data.attrs.position,
                reference: el,
                parent: $parent,
                html: document.body,
            }
        })
        
        el.addEventListener('mouseenter', function() {
            show(el);
        })

        el.addEventListener('focus', function() {
            show(el);
        })

        el.addEventListener('mouseleave', function() {
            hide(el);
        })

        el.addEventListener('focusout', function() {
            hide(el);
        })
    }
}

// Remove Tooltip on our element
function removeTooltip(el) {
    if (el.TOOLTIP) 
    {
        if(el.TOOLTIP.$root && el.TOOLTIP.$root.$el && el.TOOLTIP.$root.$el.parentNode) 
        {
            el.TOOLTIP.$root.$el.parentNode.removeChild(el.TOOLTIP.$root.$el)
        }
        el.TOOLTIP.$destroy()
        el.TOOLTIP = null
    }
    delete el.TOOLTIP;
}

export default {
    name: 'tooltip',
    config: {},
    install (Vue, installOptions) {
        installOptions;
        Vue.directive('tooltip', {
            bind(el, bindings, vnode) {
                applyTooltip(el, bindings, vnode)
            },
            // We use `componentUpdated` here instead of `update`, as the former
            // waits until the containing component and children have finished updating
            // componentUpdated(el, bindings, vnode) {
            //     // Performed in a `$nextTick()` to prevent render update loops
            //     vnode.context.$nextTick(() => {
            //         applyTooltip(el, bindings, vnode)
            //     })
            // },
            unbind(el) {
                removeTooltip(el)
            }
        });
    }
};