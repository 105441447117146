<template>
    <div class="input">
        <icon :image="icon"></icon>
        <input v-model="inputVal" :type="type" :placeholder="placeholder"/>
    </div>
</template>

<script>
import icon from '@/components/UI/icon.vue';

export default {
    props: {
        value: String,
        icon: String,
        type: String,
        placeholder: String,
    },
    components: {
        icon
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    font-family: $font-family;
    position: relative;
    padding: 8px 8px 8px 40px;
    border: 1px solid $color2;
    border-radius: 4px;
    background: white;
    box-sizing: border-box;

    .icon {
        position: absolute;
        top: 3px;
        left: 4px;
    }

    input {
        display: block;
        width: 100%;
        border: none;
        border:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        outline: none;
        font-size: .9em;
        @include placeholder($color2, 0.5);

        &:active {
            box-shadow: none;
        }
    }
}
</style>