/* eslint-disable */
var meno = meno || {};
export function Request(opt, callback)
{
    var _method = opt.method || 'GET';
    var _url    = opt.url || null;
    var _path   = opt.path || null;
    var _params = opt.data || null;

    // can be used to display process bar
    var _downloadProcess = opt.downloadProcess || null; // function(progressState) from 0 to 1
    var _uploadProcess   = opt.uploadProcess || null;   // function(progressState) from 0 to 1

    var _contentType = opt.type || 'json'; // possible is data or json -> data requires a FormData instance

    if (_contentType === 'data' && _params !== null && !( _params instanceof FormData) )
    {
        console.error('Try to request data without formdata');
    }

    // trim left slash
    if (_path !== null && _path[0] === '/')
    {
        _path = _path.substr(0, _path.length - 1);
    }

    // create url if needed
    if (_url === null)
    {
        _url = document.querySelector("meta[name='api-url']").content + _path;
    }

    // initialize a request using XMLHttpRequest
    // this request is used to get data from argumentsn webserver
    var xmlHttpReq = new XMLHttpRequest();

    // handle all states of the request
    xmlHttpReq.onreadystatechange = function () {

        var DONE = this.DONE || 4;
        if (xmlHttpReq.readyState === DONE)
        {
            var jsonData     = null;
            var error         = null;

            if (this.status > 0)
            {
                try
                {
                    // is there any response? 
                    // try to parse it
                    if (xmlHttpReq.responseText.length > 0)
                    {
                        jsonData = JSON.parse(xmlHttpReq.responseText);
                    }
                }
                catch(e)
                {
                    error = 'Invalide JSON response: ' + xmlHttpReq.responseText;
                }

                // is the code higher then 300 means, there is any arror
                // try to render error message from json data
                // otherwise use default one
                if (this.status >= 300)
                {
                    if(this.status == 401)
                    {
                        window.location = './login?timeout=1';
                    }
                    else if (jsonData && jsonData.message)
                    {
                        error = jsonData.message;
                    }
                    else if (jsonData && jsonData.error)
                    {
                        error = jsonData.error.message;
                    }
                    else
                    {
                        error = 'There is an error but no message';
                    }
                }
            }

            // request cancelled
            else
            {
                error = 'cancelled';
            }

            // finish request using callback
            callback(error, jsonData, this.status);
        }
    };

    // handle process on upload / download
    if(_uploadProcess)
    {
        xmlHttpReq.upload.onprogress = function (e)
        {
            if (e.lengthComputable)
            {
                _uploadProcess(e.loaded / e.total);
            }
        }

        xmlHttpReq.upload.onloadstart = function (e)
        {
            _uploadProcess(0);
        }

        xmlHttpReq.upload.onloadend = function (e)
        {
            _uploadProcess(e.loaded / e.total);
        }
    }
    
    if(_downloadProcess)
    {
        xmlHttpReq.onprogress = function (e)
        {
            if (e.lengthComputable)
            {
                _downloadProcess(e.loaded / e.total);
            }
        }

        xmlHttpReq.onloadstart = function (e)
        {
            _downloadProcess(0.0);
            console.log('Start download', 0);
        }

        xmlHttpReq.onloadend = function (e)
        {
            _downloadProcess(e.loaded / e.total);
        }
    }


    // check are there any params and method is GET?
    var requestURL = _url;

    if(_method.toLowerCase() === 'get' && _contentType !== 'data')
    {
        requestURL += '?';
        
        for(var key in _params)
        {
            requestURL += key + '=' + encodeURIComponent(_params[key]) + '&';
        }

        requestURL = requestURL.substr(0, requestURL.length - 1);
        _params = null;
    }

    xmlHttpReq.open(_method, requestURL, true);

    // set needed header for the server
    // use content-type and accept as appliaction/json to tell the server
    // that we will send json and we only accpet json as response
    if(_contentType === 'json')
    {
        xmlHttpReq.setRequestHeader('Content-Type', 'application/json');
    }

    xmlHttpReq.setRequestHeader('Accept', 'application/json');

    // do it!
    if(_params === null)
    {
        xmlHttpReq.send();
    }
    else if(_contentType === 'json')
    {
        xmlHttpReq.send(JSON.stringify(_params));    
    }
    else
    {
        xmlHttpReq.send(_params);    
    }

    // return the request to handle cancel calles from outside
    return xmlHttpReq;    
};