<template>
    <div class="list-container">
        <List v-if="passwords.length > 0" :items="[]"/>
        <List v-if="passwords.length > 0" :no-header="true" :items="passwords"/>
        <div v-else class="centered-dialog center">
            <h2>{{ $t('admin.noPasswords') }}</h2>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { ECDH/*, Toast, Request*/ } from '@/classes'
import List from '@/components/list'

export default {
    name: 'Home',
    components: {
        List,
    },
    data: function() {
        return {
            passwords: [],
        }
    },
    methods: {
        init: async function() {
            if(this.$publicKey && this.$privateKey)
            {
                var ecdh = new ECDH({
                    publicKey: this.$publicKey,
                    privateKey: this.$privateKey,
                });
                await ecdh.init();

                try 
                {
                    var res = await this.$passwordController.findAll(this.$route.params.hash);

                    this.passwords = await Promise.all(res.passwords.map(async function(password) {
                        try
                        {
                            await ecdh.usePubKey(password.key);
                            
                            var jsonString = await ecdh.decrypt(password.data);
                            var pw = JSON.parse(jsonString);
    
                            return {
                                id: password.id,
                                urlField: pw.url,
                                loginField: pw.login,
                                passwordValue: pw.password,
                                passwordField: '••••••••••••',
                                comment: pw.comment,
                            }
                        }
                        catch(e)
                        {
                            return {
                                id: password.id,
                                urlField: null,
                                loginField: null,
                                passwordField: null,
                                comment: null,
                                error: true,
                            }
                        }
                    }));
                }
                catch(e)
                {
                    return;
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.space {
    margin: 20px auto 0;
}
.list-container {
    padding: 100px 20px;
    overflow: auto;
    max-height: 100%;
    box-sizing: border-box;
    overflow: visible;
}
</style>