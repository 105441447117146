<template>
    <div class="swap-container">
        <div class="swappable" :style="{ 'margin-left': displacement + '%' }">
            <div class="centered-dialog center">
                <h1>{{ $t("landing.title") }}</h1>
                <h2>{{ $t("landing.subtitle") }}</h2>
                <div>{{ $t("landing.introText") }}</div>
                <Button class="space" :text="$t('landing.createAccess')" @click="generateKeys"/>
            </div>
        </div>
        <div class="swappable">
            <div class="centered-dialog center">
                <h1>{{ $t('landing.urlGenerated.title') }}</h1>
                <h2>{{ $t('landing.urlGenerated.subtitle') }}</h2>
                <div>{{ $t('landing.urlGenerated.text') }}</div>
                <Input class="space" :value="url" icon="world"/>
                <div class="actions">
                    <Button class="space" @click="downloadKey">
                        <Icon position="bottom"  image="key"  color="white"/>
                        <span>{{ $t('landing.key') }}</span>
                    </Button>
                    <Button class="space" @click="$clipboard(url, { success: $t('toast.copySuccess'), failure: $t('toast.copyFailure') })">
                        <Icon position="bottom"  image="copy" color="white"/>
                        <span>{{ $t('landing.copyUrl') }}</span>
                    </Button>
                    <Button class="space" @click="enterMain">
                        <Icon position="bottom"  image="user" color="white"/>
                        <span>{{ $t('landing.goToAdmin') }}</span>
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Button, Input, Icon } from "@/components/UI";
import { ECDH, Toast } from "@/classes";

export default {
    components: {
        Button,
        Input,
        Icon,
    },
    data: function() {
        return {
            status: false,
            displacement: 0,
            url: null,
            hash: null,
            pub: null,
            prv: null,
        }
    },
    methods: {
        generateKeys: async function() {
            var ecdh = new ECDH();
            await ecdh.init();

            this.pub = await ecdh.exportPublicKey();
            this.prv = await ecdh.exportPrivateKey();

            // import key for secret
            await ecdh.usePubKey(this.pub);

            // generate any encrypted text with the own key
            // to check whether a later imported private key
            // matches the public key
            var secret = await ecdh.encrypt('secret');

            this.$shareController.create(this.pub, secret)
            .then(res => {
                this.hash = res.url;
                this.url = window.location.origin + '/#/' + res.url;
                this.$storage('key', this.prv);
                this.displacement = -100;
            }, () => {
                Toast.make(this.$t('toast.urlNotCreated')).error().show();
            });
        },
        downloadKey: function() {
            var text     = this.prv;
            var filename = 'pw_share_private_key.txt';
            this.$download(filename, text);
        },
        enterMain: async function() {
            if(this.url && this.hash) 
            {
                this.$router.push({ name: 'Main', params: { hash: this.hash } });
            }
            else
            {
                Toast.make(this.$t('landing.errors.urlInvalid')).error().show();
            }
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.space {
    margin: 20px auto 0;
}
button {
    max-width: 300px;
}
.actions {
    // @include flexbox;
    @include justify-content(flex-end);

    button {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        // font-size: 0.9rem;
        .icon {
            margin-right: 8px;
        }
    }
}
</style>