import { Toast, Request } from "@/classes";

var PasswordController = function() {
    var uri = function(hash) {
        return 'share/' + hash + '/passwords';
    }

    PasswordController.prototype.create = async function(hash, password, key) {
        return new Promise(function(resolve, reject) {
            Request({
                url: uri(hash),
                method: 'POST',
                data: {
                    password: password,
                    key: key,
                }
            }, function(err, res) {
                if(err)
                {
                    Toast.make('Das Passwort konnte nicht gespeichert werden.').error().show();
                    reject(err);
                }
                else
                {
                    resolve(res);
                }
            });
        });
    }

    PasswordController.prototype.findAll = async function(hash) {
        return new Promise(function(resolve, reject) {
            Request({
                url: uri(hash),
                method: 'GET',
            }, function(err, res) {
                if(err)
                {
                    if(res && res.code == 404)
                    {
                        Toast.make('Es wurden keine Passwörter gefunden.').error().show();
                    }
                    reject(err);
                }
                else
                {
                    resolve(res);
                }
            });
        });
    }
    
    PasswordController.prototype.remove = async function(hash, id) {
        return new Promise(function(resolve, reject) {
            Request({
                url: uri(hash) + '/' + id + '/remove',
                method: 'GET',
            }, function(err, res) {
                if(err)
                {
                    Toast.make('Das Passwort konnte nicht gelöscht werden.').error().show();
                    reject(err);
                }
                else
                {
                    resolve(res);
                }
            });
        });
    }
}

export default PasswordController;