<template>
    <div>
        <div class="centered-dialog center">
            <h1>{{ $t('passwordAdd.title') }}</h1>
            <h2>{{ $t('passwordAdd.subtitle') }}</h2>
            <div class="simple-grid">
                <Input icon="user" :placeholder="$t('passwordAdd.placeholders.login')" v-model="login" class="space"/>
                <Input icon="lock" :placeholder="$t('passwordAdd.placeholders.password')" v-model="password" class="space"/>
            </div>
            <Input icon="world" v-model="url" :placeholder="$t('passwordAdd.placeholders.url')" class="space"/>
            <Textarea v-model="comment" class="space" icon="message" :placeholder="$t('passwordAdd.placeholders.encryptedText')"/>
            <Button class="space" :text="$t('passwordAdd.share')" @click="save"></Button>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { Input, Button, Textarea } from '@/components/UI'
import { ECDH, Toast } from '@/classes'

export default {
    name: 'Home',
    components: {
        Input,
        Button,
        Textarea
    },
    data: function() {
        return {
            login: '',
            password: '',
            url: '',
            comment: '',
        }
    },
    methods: {
        save: async function() {
            var doSave = true;

            if(!this.$publicKey) 
            {
                alert(this.$t('passwordAdd.errors.noPublicKey'))
                return;
            }

            if(!this.login || this.login.length < 1) 
            {
                doSave = false;
            }
            
            if(!this.password || this.password.length < 1) 
            {
                doSave = false;
            }

            if(doSave) 
            {
                var userEcdh = new ECDH();
                await userEcdh.init();
                await userEcdh.usePubKey(this.$publicKey);
        
                var data = {
                    url: this.url,
                    comment: this.comment,
                    password: this.password,
                    login: this.login,
                };
        
                try 
                {
                    var jsonDataString = JSON.stringify(data);
                    var encryptedData = await userEcdh.encrypt(jsonDataString);
                    var userPubKey = await userEcdh.exportPublicKey();
                }
                catch(e)
                {
                    Toast.make(this.$t('passwordAdd.errors.encryptionFailed')).show();
                    return;
                }

                try 
                {
                    await this.$passwordController.create(this.$route.params.hash, encryptedData, userPubKey);
                    Toast.make(this.$t('passwordAdd.sharingSuccessful')).show();
                    this.url        = '';
                    this.comment    = '';
                    this.password   = '';
                    this.login      = '';
                }
                catch(e)
                {
                    return;
                }
            }
            else 
            {
                Toast.make(this.$t('passwordAdd.errors.missingInfo')).error().show();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.space {
    margin: 20px 0 0;
}
.simple-grid {
    &>* {
		@include flex(1 0 100%);
		max-width: 100%;
    }
    
    @media screen and (min-width: 420px) {
        &>* {
            @include flex(1 0 48%);
            max-width: 48%;
        }
    }
}
</style>