import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import { Toast } from "@/classes";
import * as Languages from './lang'; 

import TooltipDirective from '@/directives/tooltip'

Toast.defineStyles(
    {
        fontWeight: 'bold',
        borderRadius: 'none',
        background: '#2ea0a3',
        width: 'auto',
        'max-width': '330px',
    }, {
    error: {
        background: '#b33a3a',
    },
    transitions: {
        show: {
            transform: 'translateY(-70px) translateZ(0px)',
        }
    }
});


Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(TooltipDirective)

new Vue({
  router,
  store,
  render: h => h(App),
  i18n: new VueI18n({
    locale: navigator.language || navigator.userLanguage,
    messages: {
        de: Languages.de,
        en: Languages.en,
    }, // set locale messages
  })
}).$mount('#app')
